import styled, { keyframes } from 'styled-components';
import { Row, headerTextColor, mainBgColor, Column, sectionColor } from '../../UIKit';
interface IResponsiveProps {
	isMobile?: boolean;
}
export const Head = styled(Row)`
    justify-content: space-between;
    align-items: center;
	padding: ${(props: IResponsiveProps) => props.isMobile ? '19px 10px' : '19px 36px'};
    height: 64px;
    position: fixed;
    width: 100vw;
    background-color: ${mainBgColor};
    z-index: 1;

    & + * {
        margin-top: 64px;
    }

    & > h1 {
        cursor: pointer;
        font-family: 'Poiret One', cursive;
        font-weight: normal;
        font-size: 22px;
        margin: 0;
    }

    & > nav {
        display: flex;
        gap: 30px;
    }

    & > nav > a {
        color: ${headerTextColor};
        text-decoration: none;
        font-size: 20px;
        transition: transform .3s ease;

        &.active {
            border-bottom: solid 1px ${headerTextColor};
        }

        &:not(.active):hover {
            transform: translateY(-5px);
        }
    }
`;


const slideDown = keyframes`
    0% {
        opacity:0;
        transform: translateY(-100%);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
`;


export const FloatingMenu = styled(Column)`
    position: absolute;
    top: 0;
    left:0;
    background-color: ${sectionColor};
    color: ${mainBgColor};
    width: 100%;
    height: 100vh;
    padding: 0 36px;
    animation-name: ${slideDown};
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    & > nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        gap: 50px;
        & > a.active {
            border-bottom: solid 1px ${mainBgColor};
        }
    }
    & > p {
        margin:0
    }
`
