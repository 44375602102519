import MDEditor from "@uiw/react-md-editor";
import styled from "styled-components";
export * from "./svgIcons";

export const mainBgColor = "#FFEDE0";
export const sectionColor = "#131F2D";
export const textColor = "#000000";
export const headerTextColor = "#242424";
export const quoteTextColor = "#17879F";

export const sectionFont = "Rosarivo";
export const mainFont = "Quicksand";

interface IFlexProps {
	alignItems?: string;
	justifyContent?: string;
	gap?: number;
}

interface IResponsiveProps {
	isMobile?: boolean;
}

export const Row = styled.div`
	display: flex;
	align-items: ${(props: IFlexProps) => props.alignItems || "default"};
	justify-content: ${(props: IFlexProps) =>
		props.justifyContent || "default"};
	gap: ${(props: IFlexProps) => props.gap || 0}px;
`;

export const Column = styled(Row)`
	flex-direction: column;
	align-items: ${(props: IFlexProps) => props.alignItems || "default"};
	justify-content: ${(props: IFlexProps) =>
		props.justifyContent || "default"};
	gap: ${(props: IFlexProps) => props.gap || 0}px;
`;

export const ResponsiveRow = styled(Row)`
	flex-direction: ${(props: IResponsiveProps) =>
		props.isMobile ? "column" : "row"};
`;

export const Main = styled(Column)`
	overflowx: hidden;
	flex: 1;
	padding: 0 10%;
	@media (max-width: 800px) {
		padding: 0 10px;
	}
`;

export const SlidingPage = styled.div`
	opacity: 1;
	transition: opacity 0.3s ease;
	&.bye {
		opacity: 0;
	}
`;

export const SectionTitle = styled.h2`
	font-family: ${sectionFont};
	font-weight: normal;
	margin: 0;
	text-align: left;
	font-size: 3rem;
	@media (max-width: 800px) {
		font-size: 2rem;
	}
`;

export const SectionSubTitle = styled(SectionTitle)`
	background-color: ${sectionColor};
	color: ${mainBgColor};
	padding: 0 6px;
	width: 100%;
	text-align: left;
	overflow: hidden;
	height: 70px;
	@media (max-width: 800px) {
		height: 45px;
	}
`;

export const ActionButton = styled.a`
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: ${sectionColor};
    border:none;
    color:${mainBgColor};
    font-family: ${mainFont};
    font-size: 1.5rem;
    font-weight: 600;
    padding: 10px 17px;
    border-radius: 7px;
    display: flex;
    gap: 20px;
    align-items: center;
}
`;

export const DiscreteButton = styled.a`
	cursor: pointer;
`;
interface ISeparatorProps {
	height: number;
	color: string;
}

export const Separator = styled.div`
	height: ${(props: ISeparatorProps) => props.height}px;
	border-radius: 4px;
	min-width: 4px;
	background-color: ${(props: ISeparatorProps) => props.color};
`;

export const Markdown = styled(MDEditor.Markdown)`
	background: none;
	color: ${textColor};
	font-family: ${mainFont};

	& img {
		width: inherit !important;
		max-width: inherit !important;
		margin-left: inherit !important;
	}

	& > h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: ${headerTextColor};
		font-size: 3rem;
		font-family: ${sectionFont};
		font-weight: normal;
		border: none;
		& > a {
			display: none;
		}
	}

	& h3,
	h2,
	h4 {
		font-size: 2rem;
	}
	& a {
		color: black;
		border-bottom: solid 1px black;
		&:hover {
			text-decoration: none;
		}
	}
`;

export const Page = styled.div`
	padding: 150px 0;
`;
