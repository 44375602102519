import styled from 'styled-components';
import { Row, sectionColor as bgColor, mainBgColor as textColor } from '../../UIKit';

export const Foot = styled(Row)`
    background-color: ${bgColor};
    font-size: 20px;
    font-weight: 100;
    color: ${textColor};
    padding: 19px 100px;
    min-height: 96px;
    align-items: center;
    justify-content: center;
    & svg {
        height: 27px;
    }
`;