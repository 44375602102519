import React from 'react';
import { BehanceIconL, LinkedinIconL, MediumIconL, Row, Separator, mainBgColor, DiscreteButton } from '../../UIKit';
import { Foot } from './styles';

export const Footer: React.FunctionComponent = () => {
    return <Foot>
        <Row alignItems="center" gap={100} justifyContent="space-between">
            <Row gap={100}>
                <DiscreteButton href="https://www.behance.net/Berlioz-Alviz" target="_blank">
                    {BehanceIconL}
                </DiscreteButton>
                <DiscreteButton href="https://www.linkedin.com/in/blas-alviz/" target="_blank">
                    {LinkedinIconL}
                </DiscreteButton>
                <DiscreteButton href="https://medium.com/@berliozblas" target="_blank">
                    {MediumIconL}
                </DiscreteButton>
            </Row>
            <Separator height={42} color={mainBgColor} />
            <div>
                <p>Let me know how I can help!<br />
                    alvizblas@gmail.com
                </p>
            </div>
        </Row>
    </Foot>
}