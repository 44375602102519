import React, { createContext } from "react";

export const Context = createContext({
	isOnMobile: false,
	setIsOnMobile: (mode:boolean) => {},
});

interface IContextProviderProps {
	children: React.ReactNode;
}
export const ContextProvider: React.FunctionComponent<
	IContextProviderProps
> = ({ children }) => {
	const [isOnMobile, setIsOnMobile] = React.useState<boolean>(false);

	React.useEffect(() => console.log(isOnMobile), [isOnMobile]);

	const contextValues: any = {
		isOnMobile,
		setIsOnMobile,
	};

	return (
		<Context.Provider value={contextValues}>{children}</Context.Provider>
	);
};
