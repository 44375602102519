import React from 'react';
import { Routes, Route } from 'react-router-dom';

interface IMainRedirectProps {
    publicApp: any,
    privateApp: any,
    prefix: string
}

export const MainRedirect: React.FunctionComponent<IMainRedirectProps> = ({ publicApp, privateApp, prefix }) => {
    return <Routes>
        <Route path={`${prefix}/*`} element={privateApp} />
        <Route path="/*" element={publicApp} />
    </Routes>
}