import { gql } from "@apollo/client";

export const PAGE_VIEW = gql`
query MyQuery($path: String) {
    view(where: { path: $path }) {
        id
        body
        assets {
            url
            }
    }
}`


export const ALL_VIEW = gql`
query MyQuery {
    views {
        id
        path
    }
}`