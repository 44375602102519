import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import { MainRedirect } from './_MainRedirect';
import { Admin } from './_Admin';

const client = new ApolloClient({
  uri: "https://api-eu-central-1.graphcms.com/v2/cl3yulm4f0oms01xs899r8wv8/master", // process.env.REACT_APP_GRAPHCMS_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`
  },
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <MainRedirect publicApp={<App />} privateApp={<Admin prefix="/admin" />} prefix="/admin" />
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
