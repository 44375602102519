import { useQuery } from "@apollo/client";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { WORKS } from "../../gql/queries/work";
import { BurgerMenu } from "../BurgerMenu";
import { DiscreteDropMenu } from "../DiscreteDropMenu";
import { Head, FloatingMenu } from "./styles";
import { usePortfolioContext } from "../ContextManager/usePortfolioContext";
const MAIN_TITLE = "Blas Alviz";

export const Header: React.FunctionComponent = () => {
	const navigate = useNavigate();

	const [hasOpenMenu, setHasOpenMenu] = React.useState<boolean>(false);
	const [isSmallDevice, setIsSmallDevice] = React.useState<boolean>(false);

	const {
		data: worksData,
		error: worksError,
		loading: worksLoading,
	} = useQuery(WORKS);

	const handleDeviceSize = () => {
		setIsSmallDevice(window.innerWidth <= 800);
	};
	const { isOnMobile, setIsOnMobile } = usePortfolioContext();

	React.useEffect(() => {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			setIsOnMobile(true);
		}
	}, [setIsOnMobile]);

	React.useEffect(() => {
		handleDeviceSize();
		window.addEventListener("resize", handleDeviceSize);
		return () => window.removeEventListener("resize", handleDeviceSize);
	}, []);

	const handleOpenMenu = () => setHasOpenMenu(!hasOpenMenu);

	const handleOnClick = (e: any, setPath?: string) => {
		e.preventDefault();
		setHasOpenMenu(false);
		const path = setPath || `/${e.target.href?.split("/").pop()}`;
		if (path === window.location.pathname) return;
		const currentView = document.getElementById(
			`slide-${window.location.pathname.slice(1)}`
		);
		currentView?.classList.add("bye");
		window.setTimeout(() => {
			navigate(path!);
			currentView?.classList.remove("bye");
		}, 200);
	};

	return (
		<>
			<Head isMobile={isOnMobile}>
				<h1 onClick={(event: any) => handleOnClick(event, "/")}>
					{MAIN_TITLE}
				</h1>
			</Head>
		</>
	);
};
