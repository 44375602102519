import React from "react";
import { Routes, Route } from "react-router-dom";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { ContextProvider } from "./components/ContextManager";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { NoRoutes } from "./components/NoRoutes";
import { WorkDetails } from "./components/WorkDetails";
import { Main, SlidingPage } from "./UIKit";
import { text } from "stream/consumers";

export const App: React.FunctionComponent = () => {
	const SlideRoutes = ({ children }: any) => {
		const SlideChildren = children.map((child: any) => {
			const { element, path } = child.props;
			const transitionElement = (
				<SlidingPage id={`slide-${path.slice(1)}`}>
					{element}
				</SlidingPage>
			);
			return React.cloneElement(child, {
				key: path,
				element: transitionElement,
			});
		});
		return <Routes>{SlideChildren}</Routes>;
	};

	return (
		<ContextProvider>
			<Header />
			<Main>
				<div style={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
					textAlign: "center",
				}}>

					<h3
						style={{
							fontWeight: 400,
							lineHeight: 1.5,
						}}
					>I am redesigning my portfolio!<br />
						In the meantime, <a style={{
							borderBottom: "1px solid #000",
							fontWeight: 600,
							color: "#131f2d"

						}} href="https://blasalviz.framer.website">
							please have a look at my work here
						</a>
						<p>

							Or contact me at <a style={{
								borderBottom: "1px solid #000",
								fontWeight: 600,
								color: "#131f2d"

							}} href="mailto:alvizblas@gmail.com">
								alvizblas@gmail.com
							</a>
						</p>
					</h3>
				</div>
				{/* <SlideRoutes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/work/:id" element={<WorkDetails />} />
					<Route path="/*" element={<NoRoutes />} />
				</SlideRoutes> */}
			</Main>
			<Footer />
		</ContextProvider >
	);
};
