import { useQuery } from '@apollo/client';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { ALL_VIEW } from '../gql/queries/view';
import { WORKS } from '../gql/queries/work';
import { Login } from './src/components/Login';


interface IAdminProps {
    prefix: string
}

export const Admin: React.FunctionComponent<IAdminProps> = ({ prefix }) => {
    const [loggedInUser, setLoggedInUser] = React.useState<any>(null);

    const { data: viewsData, error: viewsError, loading: viewsLoading } = useQuery(ALL_VIEW);
    const { data: worksData, error: worksError, loading: worksLoading } = useQuery(WORKS);

    console.log(viewsData)
    console.log(worksData)

    if (!loggedInUser) return <Login setLoggedInUser={setLoggedInUser} />;

    return <>
        <header>
            <h1>Hey Blas</h1>
        </header>
        <h2>Pages</h2>
        <ul>
            {viewsData?.views.map((v: any) => <li key={v.id}>{v.path}</li>)}
        </ul>
        <h2>Works</h2>
        <ul>
            {worksData?.works.map((v: any) => <li key={v.id}>{v.title}</li>)}
        </ul>
    </>
}