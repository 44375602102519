import { gql } from "@apollo/client";

export const WORK = gql`
  query getWork($id: ID) {
    work(where: {id: $id}) {
        id
        title
        body
        secret
        description
        context
        role
        methods
        nextItem {
            id
            title
            description
            image {
                url
                fileName
            }
        }
        image {
            url
            fileName
		}
		video 
    }
}`;

export const WORKS = gql`
  query getWorks {
    works {
		id
		index
        title
        description
        context
        methods
        role
        image {
            url
            fileName
        }
    }
}`;
