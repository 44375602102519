import React from 'react';

interface ILoginProps {
    setLoggedInUser: (user: any) => void;
}
export const Login: React.FunctionComponent<ILoginProps> = ({ setLoggedInUser }) => {
    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const handleUsername = (event: any) => setUsername(event.target.value);
    const handlePassword = (event: any) => setPassword(event.target.value);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log(username, password);
        setLoggedInUser({})
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="username" value={username} placeholder="Username" onChange={handleUsername} />
            <input type="password" name="password" value={password} placeholder="Password" onChange={handlePassword} />
            <button type="submit">Login</button>
        </form>
    )
};